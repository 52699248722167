.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  background-color: rgb(8, 8, 8) !important;
  overflow-x: hidden;
}
#body {
  /* position:absolute; */
  background-image: linear-gradient(rgb(8, 8, 8), black);
  /* z-index: -5; */
  padding: 50px 0;
}

/* nav bar */
@media only screen and (min-width: 500px) {
  #aboutme {
    font-size: 2vw;
  }
  #nav-bar {
    font-size: 18px;
  }
  #nav-bar-nofix {
    font-size: 18px;
  }
  #projectblock {
    font-size: xx-large;
  }
  #experience-block {
    font-size: xx-large;
    padding: 8% 23% 7%;
  }
  #involvement-block {
    font-size: xx-large;
    margin: 4% 23%;
  }
}

@media only screen and (max-width: 500px) and (min-width: 430px) {
  #aboutme {
    font-size: x-large;
  }
  #nav-bar {
    font-size: medium;
  }
  #nav-bar-nofix {
    font-size: medium;
  }
  #image-banner {
    top: initial !important;
  }
  #image-banner > img {
    height: initial !important;
  }
  #projectblock {
    font-size: large;
  }
  #experience-block {
    font-size: large;
    margin: 8% 17% 7%;
  }
  #involvement-block {
    font-size: large;
    margin: 4% 17%;
  }
}

@media only screen and (max-width: 429px) and (min-width: 350px) {
  #aboutme {
    font-size: large;
  }
  #nav-bar {
    font-size: small;
  }
  #nav-bar-nofix {
    font-size: small;
  }
  #image-banner {
    top: initial !important;
  }
  #image-banner > img {
    height: initial !important;
  }
  #projectblock {
    font-size: large;
  }
  #experience-block {
    font-size: large;
    margin: 8% 17% 7%;
  }
  #involvement-block {
    font-size: large;
    margin: 4% 17%;
  }
}
@media only screen and (max-width: 349px) {
  #aboutme {
    font-size: large;
  }
  #nav-bar {
    font-size: x-small;
  }
  #nav-bar-nofix {
    font-size: x-small;
  }
  #image-banner {
    top: initial !important;
  }
  #image-banner > img {
    height: initial !important;
  }
  #projectblock {
    font-size: large;
  }
  #experience-block {
    font-size: large;
    margin: 8% 17% 7%;
  }
  #involvement-block {
    font-size: large;
    margin: 4% 17%;
  }
}

#nav-bar {
  background-color: transparent;
  position: fixed;
  padding: 10px 15px;
  width: 100%;
  z-index: 1000;
  /* font-size: 1em; */
}

#nav-bar-nofix {
  background-color: transparent;
  padding: 10px 15px;
  width: 100%;
  z-index: 1000;
  /* font-size :1.13em; */
}

#nav-bar:hover {
  animation: darken-nav 0.5s forwards;
}

@keyframes darken-nav {
  0% {
    background-color: transparent;
  }
  100% {
    background-color: rgba(0, 0, 0, 1);
  }
}

#nav {
  display: inline-flex;
  list-style: none;
  justify-content: flex-end;

  margin: 0 0;
  width: 100%;
  padding-right: 2%;
}

#nav-name {
  position: absolute;
  float: left;
}

#nav-name > a {
  color: white;
  text-decoration: none;

  font-weight: bold;
}

#nav > li {
  padding-left: 2%;
}

#nav > li > a {
  color: gainsboro;
  text-decoration: none;

  padding: 5% 12% 7%;
  white-space: nowrap;
}

#nav > li > a:hover {
  border-radius: 6px;
  animation: fadein 0.5s forwards;
}

@keyframes fadein {
  0% {
    background-color: transparent;
    color: gainsboro;
  }
  100% {
    color: white;
    background-color: cornflowerblue;
  }
}

/* image banner */
#image-banner {
  position: relative;

  top: -30px;
  overflow: hidden;
  z-index: -1;
  margin-bottom: 60px;
}

/* #image-banner > img {
  width: 100%;
  /* height: 120vh; */
/* }  */

/* about */

#aboutme {
  display: inline-flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  /* font-size: xx-large; */
  /* font-size: 2vw; */
}

#about {
  text-align: center;
  color: white;
  top: -70px;
  margin-bottom: 1%;
  /* background-color: rgb(196, 178, 120); */
  font-weight: bold;
}

#about-text {
  color: white;

  width: 70%;
  /* background-color: rgb(196, 178, 120); */
  padding: 5% 7%;
  margin-bottom: 4%;
  font-size: 70%;
}
.colortext {
  color: rgb(230, 223, 130);
  padding-left: 2.5%;
  width: 95%;
}

.code {
  color: cornflowerblue;
}

#contact {
  color: white;
  text-align: center;
}
#contact > a {
  color: rgb(230, 223, 130);
  text-decoration: none;
}

#contact > a:hover {
  color: white;
}

#backtotop {
  text-align: center;
  color: white;
  opacity: 0.7;
}

#backtotop:hover {
  animation: textfadein 0.5s forwards;
}

@keyframes textfadein {
  0% {
    color: white;
    opacity: 0.7;
  }
  100% {
    color: white;
    opacity: 1;
  }
}

#projectblock {
  display: inline-flex;
  flex-direction: column;
  align-items: center;

  width: 80%;
  margin: 0 10%;
  /* font-size: xx-large; */
}

#project {
  padding-top: 4%;
  color: white;
  text-align: center;
  font-weight: bold;
}

.projects-title {
  display: flex;
  margin-left: 15px;
  padding: 10px 0;
  color: white;
  flex-direction: row;
  align-items: baseline;
  font-size: 100%;
  font-weight: bold;
}

.project-date {
  position: absolute;
  right: 10%;
}

.projects {
  display: flex;
  margin-left: 15px;
  color: white;
  flex-direction: row;
  align-items: flex-start;
  align-content: flex-start;
}
.project-img {
  width: 50%;
}
.project-img > img {
  width: 100%;
}
.project-text {
  width: 50%;
  padding: 0 5% 0 3%;
  font-size: 60%;
}

.project-text > h5 {
  font-size: 1em;
}

.proj-hover {
  padding: 10px 0 30px 0;
}
/* .proj-hover:hover{
    background: linear-gradient(86deg, #080808, #080808, #8a89c0);
    background-size: 160% 160%;

    -webkit-animation: AnimationName 2s ease forwards;
    -moz-animation: AnimationName 2s ease forwards;
    animation: AnimationName 2s ease forwards;
}

@-webkit-keyframes AnimationName {
    0%{background-position:5% 0%}
    100%{background-position:76% 100%}
}
@-moz-keyframes AnimationName {
    0%{background-position:5% 0%}
    100%{background-position:76% 100%}
}
@keyframes AnimationName {
    0%{background-position:5% 0%}
    100%{background-position:76% 100%}
} */

#experience-block {
  color: white;

  font-weight: bold;
}

#involvement-block {
  color: white;

  font-weight: bold;
}

.title-indent {
  margin-left: 3em;
  font-size: 75%;
}

.title-indent .colortext {
  margin-left: -2.6%;
}

.text-indent {
  margin-left: 6em;
  font-size: 55%;
  font-weight: normal;
}

#resume {
  text-align: center;
  color: cornflowerblue;
}

#email {
  padding-left: 0.7em;
}

#starry-left {
  position: absolute;
  left: -17.5rem;
  top: 20vh;
  width: 37vw;
  z-index: -1;
}

#starry-right {
  position: absolute;
  right: -17.5rem;
  top: 20vh;
  width: 37vw;
  overflow-x: hidden;
  z-index: -1;
}
